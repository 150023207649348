<a  data-layout="column" data-layout-align="center center"  [href]="href" download="{{filename}}"> 
  <mat-card appearance="raised">
      <div data-layout="row" data-layout-align="end" style="padding-top: 0.3em; padding-left: 0.8em; padding-bottom: 0.1em; padding-right: 0.8em">
        <div>
          <mat-card-subtitle>
            {{title}}
          </mat-card-subtitle>
          <div>
            {{description}}
          </div>
        </div>
        <div>
          <img [src]="icon" [alt]="altDescription" [attr.height]="iconHeight" [attr.width]="iconWidth">
        </div>
      </div>
  </mat-card>
</a>