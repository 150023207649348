<div class="stats_div" data-layout-align="start center">
    <table class="stats">
        <tr>
            <td>{{totalFamilies ? (totalFamilies | number) : "..."}}</td>
            <td>Total Families</td>
        </tr>
        <tr>
            <td>{{curatedFamilies ? (curatedFamilies | number) : "..."}}</td>
            <td>Curated Families</td>
        </tr>
        <tr>
            <td>{{coveredSpecies ? (coveredSpecies | number) : "..."}}</td>
            <td>Species</td>
        </tr>
    </table>
</div>