<div class="leaderboard">  
    <mat-card>
        <mat-toolbar>
          <span>Community Submissions</span>
        </mat-toolbar>
        <table mat-table [dataSource]="dataSource">

            <!--- Note that these columns can be defined in any order. -->
            <!-- Name Column -->
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef> Submitter </th>
              <td mat-cell [matTooltip]="element.tooltip" *matCellDef="let element"> {{element.name}} </td>
            </ng-container>
          
            <!-- Count Column -->
            <ng-container matColumnDef="count">
              <th mat-header-cell *matHeaderCellDef> Count </th>
              <td mat-cell *matCellDef="let element"> {{element.count}} </td>
            </ng-container>
          
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>          
    </mat-card>
</div>  