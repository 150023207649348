<nav mat-tab-nav-bar mat-stretch-tabs [tabPanel]="tabPanel">
  <a mat-tab-link
    *ngFor="let link of navLinks"
    [routerLink]="link.path"
    routerLinkActive #rla="routerLinkActive"
    [active]="rla.isActive">
    {{link.label}}
  </a>
</nav>

<mat-tab-nav-panel #tabPanel>
  <router-outlet></router-outlet>
</mat-tab-nav-panel>
