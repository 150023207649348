<h3>
  <span *ngIf="search">{{search}}:</span> {{data?.length}} matches
  <button type="button" class="right" (click)="onDownload()">Download</button>
</h3>

<table #sort="matSort" mat-table multiTemplateDataRows matSort [dataSource]="dataSource">
  <ng-container matColumnDef="expander">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let hit"><button (click)="hit.show_alignment = !hit.show_alignment">{{hit.show_alignment ? 'v' : '&gt;'}}</button></td>
  </ng-container>
  <ng-container matColumnDef="sequence">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Sequence</th>
    <td mat-cell *matCellDef="let hit">{{hit.sequence}}</td>
  </ng-container>
  <ng-container matColumnDef="accession">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Family</th>
    <td mat-cell *matCellDef="let hit"><a routerLink="/family/{{hit.accession}}">{{hit.query}}</a></td>
  </ng-container>
  <ng-container matColumnDef="bit_score">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Bit Score</th>
    <td mat-cell *matCellDef="let hit">{{hit.bit_score}}</td>
  </ng-container>
  <ng-container matColumnDef="e_value">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>E-value</th>
    <td mat-cell *matCellDef="let hit">{{hit.e_value}}</td>
  </ng-container>
  <ng-container matColumnDef="model_start">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Model start</th>
    <td mat-cell *matCellDef="let hit">{{hit.model_start}}</td>
  </ng-container>
  <ng-container matColumnDef="model_end">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Model end</th>
    <td mat-cell *matCellDef="let hit">{{hit.model_end}}</td>
  </ng-container>
  <ng-container matColumnDef="ali_start">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Alignment start</th>
    <td mat-cell *matCellDef="let hit">{{hit.ali_start}}</td>
  </ng-container>
  <ng-container matColumnDef="ali_end">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Alignment end</th>
    <td mat-cell *matCellDef="let hit">{{hit.ali_end}}</td>
  </ng-container>
  <ng-container matColumnDef="strand">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Strand</th>
    <td mat-cell *matCellDef="let hit">{{hit.strand}}</td>
  </ng-container>

  <ng-container matColumnDef="alignment_expander">
    <td *matCellDef="let hit"></td>
  </ng-container>
  <ng-container matColumnDef="alignment">
    <td colspan="9" *matCellDef="let hit">
      <dfam-search-results-alignment *ngIf="hit.show_alignment" [hit]="hit" [getAlignCallback]="getAlignCallback">
      </dfam-search-results-alignment>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columns"></tr>
  <tr mat-row [id]="hit.row_id" *matRowDef="let hit; columns: columns"></tr>
  <tr mat-row [ngClass]="{'hidden': !hit.show_alignment}" *matRowDef="let hit; columns: ['alignment_expander', 'alignment']"></tr>
</table>
