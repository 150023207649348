<div class="chart">
    <mat-card>
        <div class="example-button-row">
            <!-- <h3>Datasets: </h3> -->
            <button mat-button (click)="makeChart('uncurated')" color="{{key === 'uncurated' ? 'primary' : ''}}">Uncurated</button>
            <button mat-button  (click)="makeChart('curated')" color="{{key === 'curated' ? 'primary' : ''}}">Curated</button>
        </div>
        <div id="pie_chart"></div>
    </mat-card>
    <dfam-stats></dfam-stats>
</div>