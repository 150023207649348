<div *ngIf="family" >
  <div class="family-content" data-layout-gt-sm="row" data-layout-align="center start" >

    <div data-flex="100" data-flex-gt-sm="90">
      <h2 class="green" style="margin-bottom:5px;">{{family.accession}}.{{family.version}}
        <span *ngIf="family.name">
        [{{family.name}}]
        </span>
      </h2>
      <div style="font-size:1.15em;">
        <mat-icon *ngIf="family.is_raw" class="raw-warning-icon"
            matTooltip="This database entry has not been validated or curated.">
          warning
        </mat-icon>
        {{family.title}}
      </div>
  
      <nav mat-tab-nav-bar mat-stretch-tabs data-hide-lt-md='true' [tabPanel]="tabPanel">
        <ng-container *ngFor="let link of navLinks">
          <a mat-tab-link
            *ngIf="link.available"
            [routerLink]="link.path"
            routerLinkActive #rla="routerLinkActive"
            [active]="rla.isActive">
            {{link.label}}
          </a>
        </ng-container>
      </nav>
  
      <button mat-flat-button [matMenuTriggerFor]="menu" data-hide-gt-sm='true'>
          <div data-layout="row" data-layout-align=" center">
          <mat-icon>menu</mat-icon> 
          <div class="menu-gap"></div>
          <h3><b class="menu-selected-label">{{menuLabel}}</b></h3>
          </div>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item *ngFor="let link of navLinks"
            (click)="setMenuLabel(link.label)"
            [routerLink]="link.path">
            <span>{{link.label}}</span>
        </button>
      </mat-menu>
      <mat-divider data-hide-gt-md='true'></mat-divider>
    
      <mat-tab-nav-panel #tabPanel>
        <router-outlet></router-outlet>
      </mat-tab-nav-panel>
    </div>
  </div>
</div>

<div *ngIf="!family">
  No such family found.
</div>
