import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dfam-help-family',
  templateUrl: './help-family.component.html',
  styleUrls: ['./help-family.component.scss']
})
export class HelpFamilyComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
