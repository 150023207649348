<mat-form-field>
  <mat-label>{{placeholder}}</mat-label>
  <mat-select name="assembly" [(ngModel)]="value">
    <mat-option>
      <ngx-mat-select-search ngModel (ngModelChange)="this.updateFilter($event)"
        placeholderLabel="Type to search"
        noEntriesFoundLabel="No matches found">
      </ngx-mat-select-search>
    </mat-option>
    <mat-option
        *ngFor="let assembly of filteredAssemblies | async"
        [value]="assembly.id" >
      {{assembly.displayName}}
    </mat-option>
  </mat-select>
</mat-form-field>
