<body data-layout="column" class="login-body">
  <div data-layout="row">
    <button flex-offset="2" label="Home" mat-button matTooltip="Return to the public site" routerLink="/home">
      <mat-icon aria-label="Login">
        <svg width="100%" height="100%" viewBox="0 0 24 24" id="ic_home_24px" x="216" xmlns="http://www.w3.org/2000/svg" fit="" preserveAspectRatio="xMidYMid meet" focusable="false"><path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z"></path></svg>
      </mat-icon>
    </button>
  </div>
  <div data-layout="column" data-layout-align="center center" style="height: 100%">
    <mat-card appearance="raised" style="min-width: 260px; max-width: 400px;">
      <mat-card-title data-layout="row" data-layout-align="center center" style="padding: 1em; margin: auto">
        <img height="55" width="177" src="assets/images/dfam_logo.png" alt="Dfam">
      </mat-card-title>
      <mat-card-content>
        <form data-layout="column" data-layout-align="space-around center">
          <a [routerLink]="['/login']" *ngIf="loginType == 'register'">Have an account?</a>
          <a [routerLink]="['/register']" *ngIf="loginType == 'login'">Need an account?</a>
          <br/>
          <mat-form-field *ngIf="loginType == 'register'">
            <mat-label>Full Name</mat-label>
            <input matInput
                type="text"
                name="fullName"
                [disabled]="isSubmitting"
                [(ngModel)]="fullName">
          </mat-form-field>
          <mat-form-field>
            <mat-label>Email</mat-label>
            <input matInput
                type="text"
                name="email"
                [disabled]="isSubmitting"
                [(ngModel)]="email">
          </mat-form-field>
          <mat-form-field>
            <mat-label>Password</mat-label>
            <input matInput
                type="password"
                name="password"
                [disabled]="isSubmitting"
                [(ngModel)]="password">
          </mat-form-field>
          <p *ngIf="loginType == 'register'">
            After registering an account and verifying your email address,
            we will enable your account for data submission.
            See the <a routerLink="/help/workbench">help page</a> for more information
            about submitting data to Dfam.
          </p>
          <button type="submit" [disabled]="isSubmitting" mat-raised-button color="primary" (click)="submitForm()">
            {{ title }}
          </button>
          <p>{{ message }}</p>
        </form>
      </mat-card-content>
    </mat-card>
  </div>
</body>

<ng-template #registerDone>
If you are a new user, an email has been sent to activate your account.

<hr>
<em>Having trouble? Send us an email at help&#64;dfam.org for assistance.</em>
</ng-template>
