<div>
  <div class="headerdiv" data-layout-gt-lg="row wrap" data-layout-lt-md="column wrap">
    <a routerLink="/">
      <img class='dflogo' src="assets/images/dfam_logo.png" alt="Dfam" />
    </a>
    <div class='sitenavdiv'>
      <a mat-button routerLink="/home" routerLinkActive="active" color="primary">HOME</a> |
      <a mat-button routerLink="/search" routerLinkActive="active" color="primary">SEARCH</a> |
      <a mat-button routerLink="/browse" routerLinkActive="active" color="primary">BROWSE</a> |
      <a mat-button routerLink="/classification" routerLinkActive="active" color="primary">CLASSIFICATION</a> |
      <a mat-button routerLink="/repository" routerLinkActive="active" color="primary">REPOSITORY</a> |
      <a mat-button href="/releases/current" color="primary">DOWNLOAD</a> |
      <a mat-button routerLink="/publications" routerLinkActive="active" color="primary">PUBLICATIONS</a> |
      <a mat-button routerLink="/help" routerLinkActive="active" color="primary">HELP</a> |
      <a mat-button routerLink="/about" routerLinkActive="active" color="primary">ABOUT</a>
    </div>
    <a href="http://www.systemsbiology.org" data-hide-lt-md='true'>
      <img class='isblogo' src="assets/images/ISB_Logo.png" alt="ISB" />
    </a>
  </div>
  <div data-layout="row" data-hide-lt-sm='false'>
    <span data-flex></span>
    <ng-container *ngIf="isAuthenticated | async; else notAuthenticated">
      <a routerLink="/workbench"><b>Workbench</b></a>
    </ng-container>
    <ng-template #notAuthenticated>
      <button mat-button label="Login" routerLink="/login">Log in
         <mat-icon><svg width="100%" height="100%" viewBox="0 0 24 24" id="login-icon" x="24" xmlns="http://www.w3.org/2000/svg" fit="" preserveAspectRatio="xMidYMid meet" focusable="false"><path d="M19,3H5C3.89,3 3,3.89 3,5V9H5V5H19V19H5V15H3V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3M10.08,15.58L11.5,17L16.5,12L11.5,7L10.08,8.41L12.67,11H3V13H12.67L10.08,15.58Z"></path></svg></mat-icon>
      </button>
    </ng-template>
  </div>
  <mat-toolbar color='primary' data-hide-gt-xs='true'>
    <span data-flex></span>
    <ng-container *ngIf="isAuthenticated | async; else notAuthenticated">
      <button mat-button label="Workbench" routerLink="/workbench">
        <b>Workbench</b>
      </button>
    </ng-container>
    <ng-template #notAuthenticated>
      <div data-layout="row" data-layout-align="center center">
        <button mat-button label="Login" routerLink="/login">Log in
          <mat-icon><svg width="100%" height="100%" viewBox="0 0 24 24" id="login-icon" x="24" xmlns="http://www.w3.org/2000/svg" fit="" preserveAspectRatio="xMidYMid meet" focusable="false"><path d="M19,3H5C3.89,3 3,3.89 3,5V9H5V5H19V19H5V15H3V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3M10.08,15.58L11.5,17L16.5,12L11.5,7L10.08,8.41L12.67,11H3V13H12.67L10.08,15.58Z"></path></svg></mat-icon>
        </button>
      </div>
    </ng-template>
  </mat-toolbar>
</div>

