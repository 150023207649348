<div>

<h3 class="underline-span">Seed Alignment Coverage and Whisker Plot <dfam-help-icon link="/help/family#seed" [tooltip]="help"></dfam-help-icon></h3>

<dfam-loader [loading]="loading">
<div style="margin-left: 2%;" *ngIf="data">
  <p><strong>Length</strong>: {{data.length}}</p>
  Representation of the <strong>{{data?.alignments?.length}}</strong> seed alignment sequences, indicating the number of aligned bases per model position and the length distribution across the model.
</div>

<div style="margin-left: 2%;" #seedContainer>
</div>

<div style="margin-left: 2%;" *ngIf="data">
  <h4 class="underline-span">Seed Sequences</h4>
  <table>
<!-- TODO: Include these statistics once the API starts including them.
    <tr><td>Sequences mapped to public databases:</td><td>{{data.publicSequences}}</td></tr>
    <tr><td>Number of assemblies represented:</td><td>{{data.representedAssemblies}}</td></tr>
-->
    <tr><td>Average Kimura Divergence:</td><td>{{data.averageKimuraDivergence ? (data.averageKimuraDivergence|percent:'1.1-1') : "N/A"}}</td></tr>
  </table>
</div>

<ng-container *ngIf="!loading && !data">
No seed alignment data is available for this family.
</ng-container>

</dfam-loader>

</div>
