<h3 class="underline-span">Relationships with other families <dfam-help-icon link="/help/family#relationships" [tooltip]="help"></dfam-help-icon></h3>

<div data-layout="row" data-layout-align="start baseline" data-layout-gap="1em">
  <mat-radio-group data-layout="row" data-layout-gap="1em"
      [(ngModel)]="filter.include" (change)="filterChanged()">
    <mat-radio-button name="include" value="all">All Dfam</mat-radio-button>
    <mat-radio-button name="include" value="related">Related Clades Only</mat-radio-button>
  </mat-radio-group>

  <mat-checkbox name="include_raw" [(ngModel)]="filter.include_raw" (change)="filterChanged()">
    Include Uncurated
  </mat-checkbox>
</div>

<dfam-loader [loading]="!relationships">

<div data-layout="row">
  <div data-layout="column" flex-offset="2">
    <div *ngIf="relationships?.length; else noData">
      <div class="key">
        <img src="/assets/images/relation_key.png">
        <p>Similarity to Forward Strand</p>
        <p>Similarity to Reverse Complement Strand</p>
      </div>
    </div>

    <ng-template #noData>
      <div *ngIf="relationships">
        No relationships found for these search options.
      </div>
    </ng-template>

    <div #outlet>
    </div>
  </div>
</div>

<div *ngIf="relationships?.length >= 300">
<mat-icon class="limit-warning-icon">warning</mat-icon>
The results shown on this page are limited to the 300 most significant
hits by e-value.
</div>

</dfam-loader>
