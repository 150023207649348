<form class="search-form">
  <mat-form-field class="full-width">
    <mat-label>Search (e.g. 'CACA' or '1' or '2-10')</mat-label>
    <input matInput #searchVal value="" (keyup)="onKeyPress(searchVal.value)">
  </mat-form-field>
</form>
<div data-layout="row" data-layout-gap="10px" class="sequence-div">
    <div data-layout="column" >
      <div *ngFor="let line of formatted_sequence_lines" class="seq-font">
       {{line.start}}
      </div>
    </div>
    <div data-layout="column" >
      <div *ngFor="let line of formatted_sequence_lines" class="seq-font" [innerHTML]="line.text">
      </div>
    </div>
    <div data-layout="column" >
      <div *ngFor="let line of formatted_sequence_lines" class="seq-font">
        {{line.end}}
      </div>
    </div> 
</div>
