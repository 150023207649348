import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dfam-help-tools',
  templateUrl: './help-tools.component.html',
  styleUrls: ['./help-tools.component.scss']
})
export class HelpToolsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
