<dfam-loader [loading]="!family">

<div #outlet>
</div>

<div data-layout="column">
<h3 class="underline-span">Coding Sequences <dfam-help-icon link="/help/family#features" [tooltip]="help.coding_seqs"></dfam-help-icon></h3>

<div *ngIf="!family?.coding_seqs?.length">
No curated coding sequences recorded for this family.
</div>

<div data-layout="row" flex-offset="1">
<div data-layout="column" data-layout-align="start stretch"  data-layout-gap="8px" flex-offset="4">
<mat-card appearance="raised" *ngFor="let cs of family?.coding_seqs">
  <mat-card-content>
    <div data-layout="column" data-layout-gap="8px">
    <div><strong>Product:&nbsp;</strong>{{cs.product}}</div>
    <div data-layout="row"><strong>Description:&nbsp;</strong><div> {{cs.description}}</div></div>
    <div><strong>Protein Type:&nbsp;</strong>{{cs.protein_type}}</div>
    <div data-layout="row">
    <mat-expansion-panel data-flex-gt-xs="50">
      <mat-expansion-panel-header>
        <mat-panel-title>Translation</mat-panel-title>
      </mat-expansion-panel-header>

      <samp class="coding_seq">
      {{cs.translation}}
      </samp>
    </mat-expansion-panel>
    </div>
    <div><strong>Frameshifts:&nbsp;</strong>{{cs.frameshifts}}</div>
    <div><strong>Stop codons:&nbsp;</strong>{{cs.stop_codons}}</div>
    <div><strong>Exons:&nbsp;</strong>{{cs.exon_count}}</div>
    <div><strong>CDS Range:&nbsp;</strong>{{cs.start}}-{{cs.end}}</div>
    <div><strong>Strand:&nbsp;</strong>{{cs.reverse ? '-' : '+'}}</div>
    </div>
  </mat-card-content>
</mat-card>
</div>
</div>
</div>

<h3 class="underline-span">Features <dfam-help-icon link="/help/family#features" [tooltip]="help.features"></dfam-help-icon></h3>

<div *ngIf="!family?.features?.length">
No features recorded for this family.
</div>

<ul>
  <li *ngFor="let feat of family?.features">
    <strong>Label:</strong> {{feat.label}}<br>
    <strong>Type:</strong> {{feat.type}}<br>
    <strong>Range:</strong> {{feat.model_start_pos}}-{{feat.model_end_pos}}<br>
    <strong>Description:</strong> {{feat.description}}<br>
    <strong>Attributes:</strong><br>
    <ul>
      <li *ngFor="let attr of feat.attributes">{{attr.attribute}}: {{attr.value}}</li>
    </ul>
  </li>
</ul>

</dfam-loader>
