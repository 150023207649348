<footer>
  <div class="container">
    <span class="attribution">
       <p>Questions or comments? Send a mail to <a href="&#109;&#97;&#105;&#108;to&#58;hel&#112;&#64;&#100;&#102;&#97;&#109;&#46;&#111;&#114;&#103;">he&#108;&#112;&#64;&#100;&#102;&#97;&#109;&#46;&#111;&#114;g</a>.</p>
        <!--   <p>Questions or comments? Send a mail to <a href="&#109;&#97;&#105;&#108;to&#58;df&#97;&#109;&#64;&#106;&#97;&#110;elia.&#104;h&#109;i.or&#103;">d&#102;a&#109;&#64;jan&#101;lia&#46;&#104;h&#109;i&#46;&#111;&#114;g</a>.</p>  -->
        This resource is made possible by funding from the National Human Genome Research Institute (NHGRI grant # U24 HG010136).
        <p class="spaced">
            <a href="http://www.systemsbiology.org/" title="ISB">Institute for Systems Biology</a>
        </p>
    </span>
  </div>
  <dfam-errors-snackbar></dfam-errors-snackbar>
</footer>
