<div>

<mat-card appearance="raised">
  <mat-card-header>
    <mat-card-title>Dfam and RepeatMasker</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <p>
    Dfam (and nhmmer) has been incorporated into RepeatMasker, a widely used
    tool for annotating interspersed repeats and low complexity DNA sequences.
    RepeatMasker offers a significantly more sophisticated method for resolving
    and post-processing of repeat annotations than dfamscan. For details on
    installing and running, please visit
    <a class="ext" href="http://www.repeatmasker.org/">the RepeatMasker site</a>.
    </p>
  </mat-card-content>
</mat-card>

<mat-card appearance="raised">
  <mat-card-header>
    <mat-card-title>Running Dfam Searches Locally</mat-card-title>
  </mat-card-header>
  <mat-card-content>
  <p>
  Dfam can also be downloaded and searches performed locally. To do this you
  will need to download three items from our web site:
  </p>

  <ol>
    <li>The Dfam HMM library, <a href="releases/current/families/Dfam.hmm.gz">Dfam.hmm</a>.
      <ul>
        <li>Or, the curated-only library which does not include "DR" entries and is smaller:
          <a href="releases/current/families/Dfam_curatedonly.hmm.gz">Dfam_curatedonly.hmm</a>.
        </li>
      </ul>
    </li>
    <li><a class="ext" href="http://hmmer.org/download.html">HMMER</a> v3.2.1,
      provided as source code. Follow the installation directions in
      the INSTALL file, contained within this tar-ball.
    </li>
    <li>The Perl script, <a href="releases/current/infrastructure/dfamscan.pl.gz">dfamscan.pl</a></li>
  </ol>

  <p>
  If you want to include Tandem Repeat Finder annotation, please obtain
  TRF from <a class="ext" href="http://tandem.bu.edu/trf/trf.html">the TRF website</a>.
  dfamscan.pl assumes that nhmmer and trf (if required) are in the executable PATH.
  </p>


  <p>
  The script dfamscan.pl is designed to manage running nhmmer and the Dfam
  database of HMMs on a query sequence, including the task of resolving
  redundant profile hits (RPHs: cases in which multiple profile HMMs match the
  same region of input sequence). This script is intended to be a first-pass to
  annotation and not as a replacement for RepeatMasker, which is a more
  thorough expert system that incorporates Dfam and nhmmer (a version of
  RepeatMasker that incorporates nhmmer and Dfam will be available soon).
  </p>

  <p>
  When searching with dfamscan.pl (or nhmmer), it is important to understand
  the two different score thresholds stored in the model. The gathering
  threshold (GA), used when running with the '--cut_ga' flag, is appropriate
  for masking the human genome, when a moderate false discovery rate (FDR) is
  acceptable. But when annotating another organism, the empirical FDR may not
  hold, so the more stringent trusted cutoff (TC) threshold, accessed using the
  '--cut_tc' flag, should be used.
  </p>

  <p>
  Models for "raw" families (accessions starting with DR) do not undergo the
  same threshold calculation process as curated families. For these models, you
  must use E-value or bit score thresholds (-E and -T respectively). Trying to
  use the precalculated threshold options will produce an error such as:
  "Error: GA bit thresholds unavailable on model _____".
  </p>

  <p>To see the full set of command line options, execute the following command:</p>

  <pre>dfamscan.pl --help</pre>

  <p>This should print a help page like this:</p>

<pre>
Command line options for controlling ./dfamscan.pl
-------------------------------------------------------------------------------
   --help       : prints this help messeage
   --version    : prints version information for this program and
                  both nhmmscan and trf
   Requires either
    --dfam_infile &lt;s&gt;    Use this is you've already run nhmmscan, and
                         just want to perfom dfamscan filtering/sorting.
                         The file must be the one produced by nhmmscan's
                         --dfamtblout flag.
                         (Note: must be nhmmscan output, not nhmmer output)
   or both of these
    --fastafile &lt;s&gt;      Use these if you want dfamscan to control a
    --hmmfile &lt;s&gt;        run of nhmmscan, then do filtering/sorting
   Requires
    --dfam_outfile &lt;s&gt;   Output file, also in dfamtblout format
   Optionally, one of these  (only -E and -T allowed with --dfam_infile)
    -E &lt;f&gt;               &gt;0, &lt;=10000
    -T &lt;f&gt;
    --masking_thresh/--cut_ga
    --annotation_thresh/--cut_tc  Default
    --species &lt;i&gt;        Currently allowed are "Other", "Homo sapiens", 
                         "Mus Musculus", "Danio rerio", "Drosophila melanogaster",
                         or "Caenorhabditis elegans"
   Optionally one of these
    --sortby_eval
    --sortby_model
    --sortby_seq         Default
   Redundant Profile Hit (RPH) removal (only if not --no_rph_removal)
    --min_cov_frac &lt;f&gt;   This is similar to the MaskLevel concept in 
                         crossmatch.  A match is considered non-redundant
                         if at least (100-min_cov_frac)% of it's aligned
                         bases are not contained within the domain of any
                         higher-scoring hit. Default: 0.75
   All optional
    --trf_outfile &lt;s&gt;    Runs trf, put results in &lt;s&gt;; only with --fastafile
    --cpu &lt;i&gt;            Default 8
    --no_rph_removal     Don't remove redundant profile hits
    --log_file &lt;s&gt;

</pre>

  <p>The recommended usage for masking is:</p>

  <pre>dfamscan.pl -fastafile myHumanSeq.fa -hmmfile Dfam.hmm -dfam_outfile myHumanSeqRegionsToMask.out --masking_thresh</pre>

  <p>And for accurate annotation:</p>

  <pre>dfamscan.pl -fastafile myChickenSeq.fa -hmmfile Dfam.hmm -dfam_outfile myChickenSeq.DfamHits.out </pre>

  <p>
  If you have already performed a search using nhmmscan and want to adjust the
  post-processing (the 'sortby' flags or the 'overlap' flags, or set a more
  stringent threshold with -E or -T), the search need not be run again. You can
  used the previous outfile as the new input. For example:
  </p>

  <pre>dfamscan.pl --dfam_infile myChickenSeq.DfamHits.out --overlap_trim 5  -T 60.0 -dfam_outfile myChickenSeq.DfamHits.out2</pre>

  <p>
  Finally, two other parameters of note. The trf flag turns on the tandem
  repeat finder search, such that the results are displayed along side the Dfam
  hits. The other is flag --cpu controls how many CPUs nhmmer will try and use.
  </p>

  <h3>A note about RPH resolution</h3>

  <p>
  Redundant Profile Hit (RPH) resolution is tricky business. Clearly redundant
  hits should be removed, and slightly overlapping hits should be retained, but
  some such decisions are not obvious. The dfamscan script takes a conservative
  approach, removing obviously overlapping hits (e.g. in cases where one hit
  clearly outscores another, even if the first is slightly shorter than the
  second), but retaining possibly-redundant matches in which two model matches
  overlap substantially but without a clear "winner". This happens, for
  example, when when one hit is slightly outscored by another much shorter hit,
  or when two hits are offset such that the 5' half of one hit overlaps the 3'
  half of another hit. It is left to downstream tools to make final decisions
  in these (relatively rare) cases.
  </p>

  </mat-card-content>
</mat-card>

</div>
